.menuHead{
    width: 100%;
    /* height: 300px; */
    min-height: 350px;
    padding: 50px 20px;
    background:  linear-gradient(45deg, hsla(0, 0%, 0%, 0.7), hsla(0, 0%, 5%, 0.6)), url('../../../public/assets/img.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    text-align: center;
    color: #fff;
}
.menuHead h4{
    font-family: ThePrestigeSignature;
    font-size: 50px;
    margin: 0;
    margin-top: 150px;
}
.menuHead h1{
    font-size: 55px;
    margin: 0;
    font-weight: 600;
}
.menuHead p{
    font-size: 15px;
    margin: 0;
    max-width: 600px;
}


.menuitems{
    width: 100%;
    /* height: 120vh; */
    background-color: var(--color-blue-300);
    padding: 150px 0;
}
.menubox{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    /* justify-content: center; */
}
.box{
    height: 400px;
    background-color: var(--color-blue-200);
    border-radius: 20px;
    overflow: hidden;
    /* padding: 10px; */
    width: 90%;
}

.box img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.box P{
    margin: 0;
    margin-top: 10px;
    margin-left: 30px;
}
.box h1{
    font-size: 25px;
    margin: 0;
    margin-top: 20px;
    margin-left: 30px;
}
.box h4{
    font-size: 25px;
    margin: 0;
    margin-left: 30px;
}










/* @media (max-width: 768px){
    .menubox{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 20px;
        justify-content: center;
    }
} */
.footer{
    width: 100%;
    background-color: var(--color-blue-500);
    padding: 100px 0;
}
.footer-set{
    display: flex;
    gap: 30px 0;
    justify-content: space-between;
}
.footer-logo img{
    width: 200px;
}
.footer-head{
    font-size: 21px;
    color: #fff;
    font-weight: 500;
    /* letter-spacing: 1px; */
}
.footer-links{
    list-style: none;
    padding: 0;
    margin-top: 20px;
}
.footer-links li{
    margin-top: 8px;
}
.footer-links li a{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    display: inline;
    transition: all .2s;
    padding: 5px 8px;
    border-radius: 5px;
}
.footer-links li a i{
    margin-right: 10px;
}
.footer-links li a:hover{
    background-color: var(--color-blue-200);
}

.footer-logo h1{
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    display: flex;
    gap: 10px;
}
.footer-logo p{
    color: #fff;
    max-width: 300px;
    font-size: 15px;
    margin-top: 20px;
}
.bottom-line{
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin-top: 80px;
}
.copyright{
    display: flex;
    color: #fff;
    margin-top: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.copyright p{
    font-size: 14px;
    margin: 0;
}
.copyright p a{
    color: #fff;
    font-weight: 500;
    text-decoration: underline;
}
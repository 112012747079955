.Error{
    width: 100%;
    height: 100vh;
    background-color: var(--color-blue-500);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.Error::before,
.Error::after{
    content: '';
    width: 330px;
    aspect-ratio: 1/1;
    background-color: var(--color-blue-200);
    position: absolute;
    z-index: 0;
    border-radius: 50%;
    opacity: .2;
    margin-bottom: 180px;
}
.Error::before{
    width: 370px;
    opacity: .3;
    z-index: 1;
    background-color: var(--color-blue-300);
}
.wrap{
    display: flex;
    gap: 50px;
    z-index: 3;
}
.wrap h1{
    color: #fff;
    font-size: 230px;
    margin: 0;
    font-family: "Baloo Chettan 2", sans-serif;
    font-weight: 400;
}
.wrap img{
    width: 280px;
    filter: drop-shadow(0 5px 10px #0000006e);
}

.Error p{
    color: var(--color-blue-200);
    text-align: center;
    max-width: 600px;
    margin-top: 60px;
    font-size: 14px;
    padding:  0 20px;
    margin-top: 100px;
    /* position: absolute;
    bottom: 200px; */
}

.err-btn{
    border: solid 1px var(--color-blue-200);
    color: #fff;
    padding: 5px 10px;
    border-radius: 30px;
    /* margin-top: 50px; */
    position: absolute;
    bottom: 100px;
}

@media (max-width: 768px){
    .wrap{
        /* flex-direction: column;
        align-items: center; */
        gap: 10px;
    }
    .wrap img{
        width: 140px;
    }
    .wrap h1{
        font-size: 120px;
    }
    .Error::before{
        width: 200px;
        margin-bottom: 220px;
    }
    .Error::after{
        width: 180px;
        margin-bottom: 220px;
    }
}
.menu{
    background-color: var(--color-blue-300);
    width: 100%;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}
.swiper-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.swiper-head h4{
    font-family: ThePrestigeSignature;
    margin: 0;
    font-size: 50px;
    color: #fff;
}
.swiper-head h2{
    margin: 0;
    font-size: 40px;
    color: #fff;
    margin-top: -20px;
}
.swiper-head P{
    max-width: 600px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    font-weight: 300;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 400px;
    background-color: var(--color-blue-200);
    margin-bottom: 50px;
    border-radius: 20px;
  }
  .swp-card{
    width: 100%;
    display: grid;
    overflow: hidden;
    grid-template-columns: 100%;
    grid-template-rows: 55%;
  }
  .swp-card .food-img{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .swp-card .food-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .5s ease-in-out;
  }
  .swp-card:hover .food-img img{
    scale: 1.1;
  }
  .card-text{
    width: 100%;
    padding: 0 20px;
  }
  .card-text h1{
    font-size: 22px;
    color: #fff;
  }
  .card-text p{
    font-size: 12px;
    color: #fff;
  }

  .order-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
  }
.btn-2{
    background-color: var(--color-blue-300);
    border: solid 1px var(--color-blue-300);
    padding: 6px 15px;
    border-radius: 28px;
    color: var(--color-white);
    font-weight: 500;
    position: relative;
    z-index: 5;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s;
  }

.btn-2:hover{
    color: aliceblue;
    background-color: var(--color-blue-200);
  }
  .order-btn h2{
    color: #fff;
    font-size: 25px;
    font-weight: 400;
  }

.slidbar{
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--color-blue-300);
    width: 280px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    /* justify-content: space-between; */
    transition: all .5s;
    padding-left: 20px;
    box-shadow: 0 0 10px #0000002a;
}
.slidebar-toggle{
    width: 100px;
    align-items: center;
    padding: 0;
}
.slide-logo{
    /* width: 100%; */
    align-items: center;
    margin-top: 30px;
    display: flex;
    transition: all .2s;
    gap: 20px;
}
.slidebar-toggle{
    padding: 0;
}
.slidebar-btn{
    opacity: 1;
    background-color: #fff;
    color: var(--color-blue-300);
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    /* transition: all .5s; */
}
.slidebar-toggle .slidebar-btn{
    rotate: 180deg;
}
.slide-logo img{
    width: 150px;
    transition: all .5s;
    visibility: visible;
}
.slidebar-toggle .slide-logo img{
    visibility: hidden;
    display: none;
}
.slide-links{
    margin-top: 150px;
}
.slide-links ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.logout,
.slide-links ul li a{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 18px;
    font-weight: 500;
    /* background-color: var(--color-blue-200); */
    color: #fff;
    padding: 8px 25px;
    border-radius: 5px;
    transition: all .5s;
    position: relative;
    transition: all .5s;
    white-space: nowrap;
}
.logout:hover,
.slide-links ul li a:hover{
    background-color: var(--color-blue-200);
}

.slide-links .active{
    background-color: var(--color-blue-200);
}
.slidebar-toggle .slide-links ul li a{
    padding: 8px 16px;
}

.slidebar-toggle .logout span,
.slidebar-toggle .slide-links ul li a span{
    position: absolute;
    left: 90px;
    /* width: 100%; */
    background-color: var(--color-blue-200);
    font-size: 15px;
    font-weight: 300;
    padding: 5px 10px;
    border-radius:  5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
}

.slidebar-toggle .logout:hover span,
.slidebar-toggle .slide-links ul li a:hover span{
    opacity: 1;
    visibility: visible;
}
.logout{
    cursor: pointer;
    position: absolute;
    bottom: 120px;
    width: 80%;
}
.menuForm{
    position: fixed;
    width: 100vW;
    height: 100vh;
    z-index: 100;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuForm .formgrid{
    /* max-width: 600px; */
    /* height: 700px ; */
    background-color: #fff;
    z-index: 1;
    border-radius: 20px;
    position: relative;
}
.menuForm .formgrid h1{
    color: var(--color-blue-500);
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    border-bottom: 1px solid;
}

.menuForm .background{
    width: 100%;
    height: 100%;
    background-color: #000000c4;
    position: absolute;
    z-index: 0;
    cursor: pointer;
}

.formgrid{
    display: flex;
    flex-direction: column;
    color: black;
    padding: 80px;
    gap: 5px;
}
.formgrid label{
    font-weight: 400;
}
.formgrid input{
    background-color: #e6e6e6;
    border: none;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: solid 1px #fff;
}
.formgrid input:focus{
    border: solid 1px var(--color-blue-200);
}

.formgrid input[type=file]::file-selector-button{
    background-color: var(--color-blue-300);
    color: #fff;
    border: none;
    padding: 2px 8px;
    border-radius: 5px;
    margin-right: 15px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.menu-add{
    border: none;
    outline: none;
    display: inline;
    width: 150px;
    padding: 10px 0;
    background-color: var(--color-blue-200);
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    margin-top: 10px;
}
.x-mark{
    position: absolute;
    right: 50px;
    top: 50px;
    background-color: #e7e7e7;
    border-radius: 50px;
    padding: 5px;
    cursor: pointer;
}

@media (max-width: 768px){
    .formgrid input{
        max-width: 270px;
    }
    .formgrid{
        padding: 50px 30px;
    }
    .x-mark{
        right: 20px;
        top: 20px;
    }
}